var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('Button',_vm._b({attrs:{"html-type":_vm.record.options.handle === 'submit' ? 'submit' : undefined},domProps:{"textContent":_vm._s(_vm.record.label)},on:{"click":function($event){_vm.record.options.handle === 'submit'
        ? false
        : _vm.record.options.handle === 'reset'
        ? _vm.$attrs.onHandleReset()
        : _vm.dynamicData[_vm.record.options.dynamicFun]
        ? _vm.dynamicData[_vm.record.options.dynamicFun]()
        : false}}},'Button',this.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }